<template>
    <div class="not-found">
        <h1 class="login-form-title">Page Not Found</h1>
        <div class="image-container">
            <img src="@/assets/img/not-found.svg" alt="Not Found" class="not-found-image" />
            <span class="image-credits">
                <i class="fas fa-camera"></i>
                <a href="https://br.freepik.com/vetores-gratis/ilustracao-isometrica-de-erro-404_7740133.htm">  Web illustrations by Storyset</a>
            </span>
        </div>
        <h3 class="mb-0 ms-0">Something went wrong.</h3>
        <p>Sorry, We can’t find the page you’re looking for.</p>
        <a href="/" class="text-decoration-none">
            <material-button size="bg">Go back to Home</material-button>
        </a>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
    name: 'NotFound',
    components: {
        MaterialButton,
    },
    beforeMount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
}
</script>

<style scoped>

a {
    color: #ffffff; /* Cor da fonte normal */
    text-decoration: none;
}

a:hover {
    color: #5496db; /* Cor da fonte ao passar o mouse */
}

.not-found {
    text-align: center;
    margin-top: 50px;
    padding: 0 20px;
}

.image-container {
    position: relative;
    /* display: inline-block; */
}

.not-found-image {
    max-width: 40%;
    max-height: 500px;
}

.image-credits {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8em;
}

.image-container:hover .image-credits {
    display: block;
}

h1 {
    font-size: 2em;
    margin: 20px 0;
}

p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    h1 {
        font-size: 1.5em;
    }

    p {
        font-size: 1em;
    }

    .not-found-image {
        max-width: 80%;
    }
}
</style>