import AuthService from '../services/auth.service';

export const auth = {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    async login({ commit }, user) {
      await AuthService.login(user);
    },
    // eslint-disable-next-line no-unused-vars
    async logout({ commit }) {
      await AuthService.logout();
    },
    // eslint-disable-next-line no-unused-vars
    async passwordForgot({commit}, userEmail){
      await AuthService.passwordForgot(userEmail);
    },
    // eslint-disable-next-line no-unused-vars
    async passwordReset({commit}, passwordDTO){
      await AuthService.passwordReset(passwordDTO);
    },
  },
};
