import { createRouter, createWebHistory } from "vue-router";
import Notifications from "../views/components/notifications/Notifications.vue";
import Login from "../views/Login.vue";
import Pabx from "../views/components/pabx/Pabx.vue";
import Users from "../views/components/users/Users.vue";
import AddPabx from "../views/components/pabx/addPabx/AddPabx.vue";
import AddUser from "../views/components/users/addUser/AddUser.vue";
import User from "../views/components/profile/User.vue";
import NotFound from "@/components/NotFound.vue";
import axios from 'axios';
import { authHeader } from '../services/auth-header';

const API_URL = `${process.env.VUE_APP_BASE_URL}:${process.env.VUE_APP_PORT}`

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/user",
    name: "Account Settings",
    component: User
  },
  {
    path: "/pabx",
    name: "Pabx",
    component: Pabx,
  },
  {
    path: "/add-pabx",
    name: "Add PABX",
    component: AddPabx,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/add-user",
    name: "Add User",
    component: AddUser,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login'];

  let hasValidAuth = true;

  try {
    await axios.get(`${API_URL}/verify`, {
      headers: {
        Authorization: authHeader(),
      }
    });
    hasValidAuth = true;
  } catch (err) {
    hasValidAuth = false;
  }

  if (!publicPages.includes(to.path) && !hasValidAuth) {
    return next('/login'); // user not even authenticated and wants to access anything. Nope!
  } else if (hasValidAuth && publicPages.includes(to.path)) {
    return next('/pabx'); // if user is authenticated and wants to access login again, why?
  }

  return next();
});


export default router;
